<template>
  <div>
    <v-dialog :persistent="isChanged" v-model="editing">
      <v-card>
        <v-card-title>Editar Ocorrência</v-card-title>
        <v-progress-circular indeterminate v-if="!occurrence" />
        <v-card-text v-if="occurrence">
          <v-form 
            :loading="loading" 
            @submit.prevent="handleUpdate"
          >
            <v-text-field 
              type="date"
              label="Data"
              v-model="date"
              :rules="rules"
            />
            <v-textarea 
              label="Descrição"
              v-model="description"
              :rules="rules"
            />

            <v-btn 
              :loading="loading" 
              class="mx-1" 
              :disabled="!isChanged" 
              color="success"
              type="submit"
            >Salvar</v-btn>

            <v-btn 
              :loading="loading" 
              class="mx-1" 
              :disabled="!isChanged" 
              @click="handleClose" 
              color="error"
            >Cancelar</v-btn>

          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    computed: {
      isChanged() {
        if(!this.occurrence) {
          return false;
        }

        if(
          this.occurrence.date === this.date &&
          this.occurrence.description === this.description
        ) {
          return false;
        }

        return true;
      }
    },
    data() {
      return {
        editing: false,
        occurrence: null,
        date: null,
        description: null,
        loading: false,
        rules: [v => !! v || 'Não pode estar vazio'],
      }
    },
    methods: {
      handleClose() {
        this.editing = false;
        this.occurrence = null;
        this.date = null;
        this.description = null;
      },
      handleEditing(value, occurrence) {
        this.editing = value;
        this.occurrence = occurrence;

        this.date = occurrence.date;
        this.description = occurrence.description;
      },
      async handleUpdate() {
        this.loading = true;

        try {
          await this.$http.put(`api/occurrences/${this.occurrence.id}`, {
              date: this.date,
              description: this.description,
              cte_id: this.occurrence.cte_id,
            });

          this.$toast.success('Salvo com sucesso');

          this.occurrence.date = this.date;
          this.occurrence.description = this.description;

          this.handleClose();
          
        } catch (error) {
          console.log(error);
          this.$toast.error('Erro ao salvar');
        }
        
        this.loading = false;
      }
    },
  }
</script>
