<template>
  <div>
    <div class="d-flex">
      <h2>Ocorrências</h2>
      <v-btn :disabled="cte.commission_id" @click="adding=true"  color="success" fab small class="mx-4">
        <v-icon title="Adicionar Ocorrência">mdi-plus</v-icon>
      </v-btn>
    </div>
      <v-data-table
        :items="cte.occurrences"
        :headers="headers"
        @click:row="handleClick"
        hide-default-footer
        disable-pagination
        :item-class="() => 'cursor-pointer'"
      >
        <template v-slot:[`item.closed`]="{ item }">
          <span>{{ item.closed ? "Fechado" : "Aberto" }}</span>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ item.date | moment("DD/MM/YYYY") }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="item.closed"
            :disabled="updating || cte.commission_id"
            title="Reabrir" 
            color="warning"
            @click="(e) => { 
              e.stopPropagation();
              setStatus(item, 'opened');
            }"
          >
            mdi-plus
          </v-icon>

          <v-icon
            v-if="!item.closed"
            :disabled="updating || cte.commission_id"
            title="Marcar como concluído" 
            color="success"
            @click="(e) => {
              e.stopPropagation();
              setStatus(item);
            }"
          >
            mdi-check
          </v-icon>

          <v-icon
            v-if="!item.closed"
            :disabled="updating || cte.commission_id" 
            title="Excluir" 
            color="error"
            @click="(e) => {
              e.stopPropagation();
              deleteOccurrence(item);
            }"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    

    <div v-if="adding">
      <v-dialog v-model="adding">
        <create-occurrence @occurrenceSaved="handleSaved" :cte="cte" />
      </v-dialog>
    </div>

    <edit-occurrence ref="editOccurrenceComponent" :occurrence="focusOccurrence" />
  </div>
</template>

<script>

import http from "@/modules/http";
import createOccurrence from './AddOccurrence';
import editOccurrence from './EditOccurrence';
import moment from 'moment'

export default {
  components: {
    createOccurrence,
    editOccurrence,
  },
  props: {
    cte: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      adding: false,
      updating: false,
      focusOccurrence: {},
      headers: [
        { text: "ID", value: "id" },
        { text: "Tipo", value: "occurrence_types.name" },
        { text: "Status", value: "closed" },
        { text: "Data", value: "date" },
        { text: "Descrição", value: "description" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  methods: {
    handleSaved() {
      this.adding = false;
      this.$emit('refresh');
    },
    handleClick(value) {
      this.$refs.editOccurrenceComponent.handleEditing(true, value);
    },
    async deleteOccurrence(oc) {
      if(confirm('Tem certeza que deseja excluir esta ocorrência?')) {
        this.updating = true;
        
        try {
          await http.delete("api/occurrences/"+oc.id);
          this.$toast.success('Excluído com sucesso');
          this.$emit('refresh');
          this.focusOccurrence = {};
        } catch(err) {
          console.log(err);
          this.$toast.error('Erro ao excluir');
        }

        this.updating = false;
      }
    },
    async setStatus(oc, status = 'closed') {
      if(status == 'closed') {
        oc.closed = 1;
      } else {
        oc.closed = 0;
      }

      this.updating = true;

      try {
        await http.put("api/occurrences/"+oc.id, oc);
        this.$toast.success('Atualizado com sucesso');
      } catch(err) {
        console.log(err);
        this.$toast.error('Erro ao salvar');
        this.$emit('setClosedError');
      }

      this.updating = false;
    },
  },
};
</script>

<style scoped>
.occurrence-container {
  background: rgb(187, 217, 221);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px;
  width: 90%;
  display: block;
  margin: 0 auto;
  text-align: center;
}
</style>