<template>
  <div>
    <v-icon
      color="error"
      @click="remove"
      :disabled="updating"
    >mdi-delete</v-icon>
  </div>
</template>

<script>

import http from '@/modules/http';

  export default {
    props: {
      palletization: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        updating: false,
      }
    },
    methods: {
      remove() {
        this.$confirm({
          type: "error",
          title: "Excluir ordem de paletização",
          content:
            "Tem certeza que deseja excluir esta ordem de paletização?",
          confirmText: "Sim",
          cancelText: "Não",
          confirm: () => {
            this.updating = true;
            http
              .delete("api/palletizations/"+this.palletization.id)
              .then(() => {
                this.updating = false;
                this.$toast.success('Removido com sucesso');
                this.$emit('refresh');
              })
              .catch((err) => {
                console.log(err);
                this.updating = false;
                this.$toast.error('Erro ao excluir paletização');
              });
            this.$close(); //If you don't want to close the dialog, you can not use it.
          },
          cancel: () => {
            this.$close(); //If you don't want to close the dialog, you can not use it.
          },
        });
      }
    },
  }
</script>

<style scoped>

</style>