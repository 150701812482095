var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('h2',[_vm._v("Ocorrências")]),_c('v-btn',{staticClass:"mx-4",attrs:{"disabled":_vm.cte.commission_id,"color":"success","fab":"","small":""},on:{"click":function($event){_vm.adding=true}}},[_c('v-icon',{attrs:{"title":"Adicionar Ocorrência"}},[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{attrs:{"items":_vm.cte.occurrences,"headers":_vm.headers,"hide-default-footer":"","disable-pagination":"","item-class":function () { return 'cursor-pointer'; }},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.closed",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.closed ? "Fechado" : "Aberto"))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.date,"DD/MM/YYYY")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.closed)?_c('v-icon',{attrs:{"disabled":_vm.updating || _vm.cte.commission_id,"title":"Reabrir","color":"warning"},on:{"click":function (e) { 
            e.stopPropagation();
            _vm.setStatus(item, 'opened');
          }}},[_vm._v(" mdi-plus ")]):_vm._e(),(!item.closed)?_c('v-icon',{attrs:{"disabled":_vm.updating || _vm.cte.commission_id,"title":"Marcar como concluído","color":"success"},on:{"click":function (e) {
            e.stopPropagation();
            _vm.setStatus(item);
          }}},[_vm._v(" mdi-check ")]):_vm._e(),(!item.closed)?_c('v-icon',{attrs:{"disabled":_vm.updating || _vm.cte.commission_id,"title":"Excluir","color":"error"},on:{"click":function (e) {
            e.stopPropagation();
            _vm.deleteOccurrence(item);
          }}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)}),(_vm.adding)?_c('div',[_c('v-dialog',{model:{value:(_vm.adding),callback:function ($$v) {_vm.adding=$$v},expression:"adding"}},[_c('create-occurrence',{attrs:{"cte":_vm.cte},on:{"occurrenceSaved":_vm.handleSaved}})],1)],1):_vm._e(),_c('edit-occurrence',{ref:"editOccurrenceComponent",attrs:{"occurrence":_vm.focusOccurrence}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }