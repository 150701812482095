<template>
  <div>
    <v-expansion-panels focusable v-model="isOpen">
      <v-expansion-panel>
        <v-expansion-panel-header>Clique para editar</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
            :disabled="cte.commission_id"
            ref="formCte"
            @submit.prevent="saveCte()"
          >
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field
                  label="CTe*"
                  :rules="requiredRules"
                  v-model="cte.number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="NFe*"
                  :rules="requiredRules"
                  v-model="cte.nf"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  :items="partners"
                  item-text="name"
                  item-value="id"
                  :loading="loading"
                  label="Parceira*"
                  :rules="requiredRules"
                  v-model="cte.partner_id"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  type="date"
                  label="Data de chegada*"
                  :rules="requiredRules"
                  v-model="cte.arrival"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  type="date"
                  label="Previsão de entrega*"
                  :rules="requiredRules"
                  v-model="cte.dpe"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-if="$auth.isAuthorized('manager')"
                  title="Caso a data de entrega seja alterada para entrar na comissão"
                  type="date"
                  label="Data de entrega"
                  v-model="cte.delivery_date"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <customer-input-edit
                  :propCustomer="cte.senders"
                  inputName="sender"
                  InputLabel="Remetente"
                  @set_customer_id="setSender"
                />
              </v-col>
              <v-col cols="12" md="6">
                <customer-input-edit
                  :propCustomer="cte.recipients"
                  inputName="recipient"
                  InputLabel="Destinatário"
                  @set_customer_id="setRecipient"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Volumes*"
                  v-model="cte.qty"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Peso*"
                  v-model="cte.weigth"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Valor NFe"
                  v-model="cte.nf_value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Valor frete*"
                  :rules="requiredRules"
                  v-model="cte.freight_value"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="ICMS % *"
                  :rules="requiredRules"
                  v-model="cte.icms"
                  v-if="$auth.isAuthorized('manager')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Valor ICMS"
                  v-model="cte.icms_value"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="2">
                <v-text-field
                    label="Valor ICMS"
                    v-model="icms_value"
                    v-if="$auth.isAuthorized('manager')"
                ></v-text-field>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-text-field label="TDA" v-model="cte.tda"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field label="TDE" v-model="cte.tde"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field label="Taxas" v-model="cte.fees"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Descarga"
                  v-model="cte.discharge"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field label="Pedágio" v-model="cte.toll"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Valor Comissão"
                  v-model="cte.commission_value"
                  v-if="$auth.isAuthorized('manager')"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row cols="12">
              <v-textarea
                label="Observações"
                v-model="cte.observations"
                :rules="[
                  (v) => {
                    if (!v || v.length < 500) {
                      return true;
                    } else {
                      return 'Máximo 500 caracteres';
                    }
                  },
                ]"
              ></v-textarea>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  type="submit"
                  color="secondary"
                  block
                  :loading="saving"
                  :disabled="cte.commission_id"
                  >Salvar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import http from "@/modules/http";
import CustomerInputEdit from "./CustomerInputEdit.vue";
export default {
  components: { CustomerInputEdit },
  watch: {
    hasAlternativeAddress() {
      if (this.hasAlternativeAddress === true) {
        if (this.cities.length < 1) {
          this.getCities();
        }
      }
    },
    icms_value() {
      this.icms_value = this.icms_value.toString();
      this.icms_value = this.icms_value.replace(",", ".");
      this.icms_value = parseFloat(this.icms_value);

      let freight_value = this.cte.freight_value;
      let icms_value = this.icms_value;

      let icms_freight = icms_value * 100;
      let x = icms_freight / freight_value;

      this.cte.icms = x;
    },
  },
  props: {
    cte: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      icms_value: ((this.cte.freight_value * this.cte.icms) / 100).toFixed(2),
      isOpen: "",
      hasAlternativeAddress: false,
      isLoadingCities: false,
      cities: [],
      saving: false,
      partners: [],
      loading: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
      refresh: false,
    };
  },
  methods: {
    setSender(e) {
      this.cte.sender_id = e.id;
      this.refresh = true;
    },
    setRecipient(e) {
      this.cte.recipient_id = e.id;
      this.refresh = true;
    },
    proccessValue(value) {
      return value ?? 0;
    },
    getCities() {
      this.isLoadingCities = true;
      http
        .get("api/cities")
        .then((res) => {
          this.cities = res.data;
          this.isLoadingCities = false;
        })
        .catch((e) => {
          console.log(e);
          this.$side({
            type: "error",
            msg: "Erro ao carregar as cidades",
            duration: 4000,
          });
          this.isLoadingCities = false;
        });
    },
    set_sender_id(customer) {
      this.cte.sender_id = customer.id;
    },
    set_recipient_id(customer) {
      this.cte.recipient_id = customer.id;
    },
    getPartners() {
      this.loading = true;
      http
        .get("api/partners")
        .then((res) => {
          this.loading = false;
          this.partners = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$side({
            type: "error",
            msg: "Não foi possível carregar as parcerias",
            duration: "5000",
          });
          console.log(err);
        });
    },
    saveCte() {
      if (this.$refs.formCte.validate()) {
        this.saving = true;
        http
          .put("api/ctes/" + this.cte.id, this.cte)
          .then(() => {
            this.saving = false;
            this.$toast.success("Salvo com sucesso");
            this.isOpen = "";

            if (this.refresh) {
              this.$emit("refresh");

              this.refresh = false;
            }
          })
          .catch((error) => {
            this.saving = false;
            console.log(error);

            let errors = Object.entries(error.response.data.errors);

            if (errors) {
              errors.forEach((e) => {
                this.$side({
                  type: "error",
                  msg: e[1][0],
                  duration: 5000,
                });
              });
            }
          });
      }
    },
  },
  mounted() {
    if (this.cte.alternative_address) {
      this.getCities();
    }
    this.getPartners();
  },
};
</script>
e>
