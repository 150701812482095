<template>
  <div>
    <h2 v-if="cte && cte.commission_id" class="red--text">
      Comissão fechada!
      <router-link :to="`/comissoes/${cte.commission_id}`"
        >Clique para acessar</router-link
      >
    </h2>

    <hr v-if="cte && cte.commission_id" />

    <div v-if="cte">
      <h3>
        Editar CTe {{ cte.number }} - {{ cutName(cte.senders.name) }} para
        {{ cutName(cte.recipients.name) }} -
        {{
          cte.alternative_city_id
            ? cte.alternative_cities.name
            : cte.recipients.cities.name
        }}
      </h3>

      <edit-form @refresh="getCte()" :cte="cte" />
    </div>

    <v-progress-linear
      indeterminate
      color="red"
      v-if="loading"
    ></v-progress-linear>
    <v-card v-if="cte" class="pa-2">
      <div class="ma-2 icons">
        <v-btn
          color="error"
          class="mx-1"
          title="Voltar"
          @click="$router.back()"
        >
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn>
        <v-btn
          @click="handleDeliveredModal"
          color="success"
          class="mx-1"
          title="Confirmar Entrega"
          v-if="!cte.delivered"
          :disabled="cte.commission_id"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn
          @click="rollbackDelivery"
          color="warning"
          class="mx-1"
          title="Extornar Entrega"
          v-if="cte.delivered"
          :disabled="cte.commission_id"
        >
          <v-icon>mdi-restore</v-icon>
        </v-btn>
        <v-btn
          :loading="loading"
          color="secondary"
          class="mx-1"
          title="Atualizar"
          @click="getCte()"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn
          v-if="$auth.isAuthorized('manager')"
          :loading="loading"
          :color="cte.freight_paid ? 'warning' : 'primary'"
          class="mx-1"
          :title="
            cte.freight_paid
              ? 'Definir como não pago'
              : 'Definir como frete já pago'
          "
          @click="setFreightPaid"
          :disabled="cte.commission_id"
        >
          {{ cte.freight_paid ? "Frete normal" : "Frete Pago" }}
        </v-btn>

        <v-btn
          :loading="loading"
          :color="cte.released ? 'error' : 'success'"
          class="mx-1"
          :title="
            cte.freight_paid
              ? 'Definir como liberado'
              : 'Definir como não liberado'
          "
          @click="setReleased"
          v-if="!cte.delivered"
        >
          {{ cte.released ? "Bloquear" : "Desbloquear" }}
        </v-btn>

        <v-btn
          v-if="$auth.isAuthorized('superAdmin')"
          color="error"
          @click="handleDelete"
          class="mx-1"
          :disabled="cte.commission_id"
        >
          <v-icon>
            mdi-delete
          </v-icon>
          Excluir
        </v-btn>
      </div>

      <div v-if="cte.schedules && cte.schedules.length > 0">
        <h2>Agendamentos</h2>
        <h4>
          Agendamento do cliente {{ cte.recipients.name }} dia
          {{ formatedDate }} às {{ formatedTime }}
          <span v-if="schedule.responsible">
            {{ ` - Agendado com ${schedule.responsible}` }}
          </span>
        </h4>
        <v-form
          :disabled="cte.commission_id"
          ref="scheduleForm"
          @submit.prevent="saveSchedule()"
        >
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field type="date" v-model="schedule.date"></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field type="time" v-model="schedule.time"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                label="Responsável"
                v-model="schedule.responsible"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-btn
                color="error"
                class="mx-1 mt-3"
                type="submit"
                :loading="savingSchedule"
                :disabled="cte.commission_id"
                >Reagendar</v-btn
              >
              <v-btn
                color="warning"
                class="mx-1 mt-3"
                :loading="savingSchedule"
                :disabled="cte.commission_id"
                @click="removeSchedule()"
                >Excluir</v-btn
              >
            </v-col>

            <v-col cols="12" md="1">
              <v-icon
                class="mt-4"
                color="primary"
                @click="showCalendar = true"
                title="Exibir Calendário de Agendamentos"
                >mdi-calendar</v-icon
              >
            </v-col>
          </v-row>
        </v-form>
      </div>

      <div v-if="!cte.commission_id">
        <div v-if="!cte.schedules || !cte.schedules.length > 0">
          <br />
          <hr />
          <br />
          <h3>Adicionar Agendamento</h3>
          <add-scheduling @schedulingSaved="getCte()" :cte="cte" />
        </div>

        <hr class="my-4" />

        <h3 v-if="cte.palletizations.length <= 0">Adicionar Paletização</h3>
        <add-palletization
          @saved="getCte()"
          v-if="cte && cte.palletizations.length <= 0"
          :cte="cte"
        ></add-palletization>

        <div class="d-flex" v-if="cte.palletizations.length > 0">
          <h3>Este CTe possui ordem de paletização</h3>
          <div class="mx-4">
            <print-palletization
              @update="getCte"
              :showEditOption="true"
              :cte="cte"
            />
          </div>
          <remove-palletization
            v-if="cte && cte.palletizations.length > 0"
            :palletization="cte.palletizations[0]"
            @refresh="getCte()"
          />
        </div>

        <hr class="my-4" />
      </div>

      <div
        class="d-flex"
        v-if="cte.palletizations.length > 0 && cte.commission_id"
      >
        <h3>Este CTe possui ordem de paletização</h3>
        <div class="mx-4">
          <print-palletization
            @update="getCte"
            :showEditOption="false"
            :cte="cte"
          />
        </div>
      </div>

      <v-row>
        <v-col cols="12">
          <h2>Movimento de entrega</h2>
          <div v-if="cte.delivered">
            <p>Entregue em {{ delivery_date | moment("DD/MM/YYYY") }}</p>
          </div>
          <div v-if="cte.deliveries.length > 0">
            <p v-for="(delivery, i) in cte.deliveries" :key="i">
              {{
                `Em rota de entrega dia ${formatDate(
                  delivery.delivering.date
                )} com ${delivery.delivering.drivers.name} - Romaneio: ${
                  delivery.delivery_id
                }`
              }}
              <v-icon
                color="red"
                @click="deleteDelivery(delivery.id)"
                :disabled="cte.commission_id"
                >mdi-delete</v-icon
              >
            </p>
          </div>
          <div>
            <p>{{ `Chegada dia ${formatDate(cte.arrival)}` }}</p>
          </div>
        </v-col>
      </v-row>

      <div>
        <show-occurrences
          @refresh="getCte"
          @setClosedError="getCte"
          :cte="cte"
        />
      </div>
    </v-card>

    <div v-if="showCalendar">
      <v-dialog v-model="showCalendar">
        <schedules-calendar />
      </v-dialog>
    </div>

    <div v-if="deliveredModal">
      <v-dialog v-model="deliveredModal">
        <set-delivered
          @deliveryDateSaved="
            () => {
              deliveredModal = false;
              getCte();
            }
          "
          :cte="cte"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";
import moment from "moment";
import SchedulesCalendar from "../components/SchedulesCalendar";
import setDelivered from "../components/setDelivered";
import showOccurrences from "../components/ShowOccurrences";
import PrintPalletization from "../components/PrintPalletization.vue";
import EditForm from "@/views/ctes/components/EditForm";
import AddPalletization from "../components/AddPalletization";
import RemovePalletization from "../components/RemovePalletization";
import AddScheduling from "../components/AddScheduling.vue";

export default {
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "F8" && !this.cte.commission_id) {
        this.handleDeliveredModal();
      }
    });
  },
  watch: {
    "$route.params.id"() {
      this.getCte();
    },
  },
  components: {
    EditForm,
    SchedulesCalendar,
    setDelivered,
    showOccurrences,
    PrintPalletization,
    AddPalletization,
    RemovePalletization,
    AddScheduling,
  },
  data() {
    return {
      deliveredModal: false,
      savingSchedule: false,
      schedule: {
        id: "",
        date: "",
        time: "",
        responsible: "",
        cte_id: null,
      },
      cte: null,
      headers: [
        { text: "Data", value: "date" },
        { text: "Hora", value: "time" },
      ],
      items: [],
      showCalendar: false,
      loading: false,
      requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    };
  },
  computed: {
    delivery_date() {
      return this.cte.delivery_date ?? this.cte.delivered;
    },
    formatedDate() {
      return moment(this.schedule.date).format("DD/MM/YYYY");
    },
    formatedTime() {
      return moment(this.schedule.time, "HH:mm:ss").format("HH:mm");
    },
  },
  methods: {
    async handleDelete() {
      if (
        confirm(
          "Tem certeza que deseja excluir este CTe? Esta ação não pode ser desfeita"
        )
      ) {
        try {
          await this.$http.delete(`api/ctes/delete/${this.cte.id}`);
          this.$toast.success("Removido com sucesso");
          this.$router.push("/");
        } catch (error) {
          this.$toast.error("Erro ao remover CTe");
        }
      }
    },
    async setReleased() {
      this.loading = true;
      try {
        let { data } = await this.$http.post(
          `api/cte-released/${this.cte.id}`,
          {
            released: !this.cte.released,
          }
        );

        this.cte = data;
      } catch (error) {
        console.log(error);
        this.$toast.error("Erro ao efetuar operação");
      }
      this.loading = false;
    },
    handleDeliveredModal() {
      let has = false;

      this.cte.occurrences.forEach((occurrence) => {
        if (occurrence.closed === 0) {
          has = true;
        }
      });

      if (has) {
        this.$toast.error("Feche as ocorrências para marcar como entregue");
        return;
      }

      this.deliveredModal = true;
    },
    removeSchedule() {
      if (confirm("Tem certeza que deseja excluir este agendamento?")) {
        this.$http
          .delete(`api/schedules/${this.schedule.id}`)
          .then(() => {
            this.$side({
              type: "success",
              msg: "Agendamento removido com sucesso",
              duration: 4000,
            });

            this.getCte();
          })
          .catch((err) => {
            console.log(err);

            this.$side({
              type: "error",
              msg: "Erro ao excluir agendamento",
              duration: 4000,
            });
          });
      }
    },
    setFreightPaid() {
      this.loading = true;

      this.cte.freight_paid = !this.cte.freight_paid;

      console.log(this.cte);

      http
        .put("api/ctes/" + this.cte.id, this.cte)
        .then(() => {
          this.$side({
            type: "success",
            msg: "Salvo com sucesso",
            duration: 4000,
          });
          this.getCte();
        })
        .catch((err) => {
          console.log(err);
          this.$side({
            type: "error",
            msg: "Erro ao marcar como frete pago",
            duration: 4000,
          });

          this.getCte();
        });
    },
    async deleteDelivery(id) {
      if (confirm("Tem certeza que deseja retirar este CTe do romaneio?")) {
        try {
          await http.delete(`api/delivery-ctes/${id}`);
          this.$toast.success("Removido!");
          this.getCte();
        } catch (error) {
          console.log(error);
          this.$toast.error("Erro ao remover do romaneio");
        }
      }
    },

    rollbackDelivery() {
      this.cte.delivered = null;

      http
        .put("api/ctes/" + this.cte.id, this.cte)
        .then(() => {
          this.$side({
            type: "success",
            msg: "CTe marcado como não entregue",
            duration: 4000,
          });
        })
        .catch((err) => {
          this.$side({
            type: "error",
            msg: "Erro ao extornar entrega",
            duration: 4000,
          });
          this.getCte();
        });
    },
    cutName(name) {
      let ref_num = 30;
      if (name.length > ref_num) {
        return name.substring(0, ref_num - 3) + "...";
      } else {
        return name;
      }
    },
    saveSchedule() {
      this.savingSchedule = true;
      http
        .put("api/schedules/" + this.schedule.id, this.schedule)
        .then((res) => {
          this.savingSchedule = false;
          this.$side({
            type: "success",
            msg: "Salvo com sucesso",
            duration: 4000,
          });
          this.getCte();
        })
        .catch((err) => {
          console.log(err);
          this.savingSchedule = false;
          this.$side({
            type: "error",
            msg: "Erro ao reagendar",
            duration: 4000,
          });
        });
    },
    getCte() {
      this.loading = true;
      http
        .get(`api/ctes/${this.$route.params.id}`)
        .then((res) => {
          this.loading = false;
          this.cte = res.data;
          this.setItems();
          if (res.data.schedules.length > 0) {
            this.schedule.cte_id = res.data.id;
            this.schedule.id = res.data.schedules[0].id;
            this.schedule.date = res.data.schedules[0].date;
            this.schedule.time = res.data.schedules[0].time;
            this.schedule.responsible = res.data.schedules[0].responsible;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$side({
            type: "error",
            msg: "Erro de conexão com o servidor",
            duration: 6000,
          });
        });
    },
    setItems() {
      let data = [];
      this.cte.schedules.forEach((e) => {
        data.push({ date: e.date, time: e.time });
      });
      data.shift();
      this.items = data;
    },
    formatTime(time) {
      return moment(time, "HH:mm:ss").format("HH:mm");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.getCte();
  },
};
</script>

<style scoped></style>
