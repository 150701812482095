<template>
  <div>
    <v-card>
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
        :active="loadCustomer"
      ></v-progress-linear>
      <v-card-title>Buscar cliente</v-card-title>
      <v-card-text>
        <v-form ref="searchCustomerForm" @submit.prevent="getCustomers()">
          <v-text-field
            append-outer-icon="mdi-send"
            v-model="cnpj_field"
            label="Nome do cliente"
            @click:append-outer="getCustomers()"
            :rules="requiredRules"
          ></v-text-field>
        </v-form>

        <v-list dense>
          <v-subheader>CLIENTES</v-subheader>
          <v-list-item-group>
            <v-list-item
              v-for="(customer, c) in customers"
              :key="c"
              @click="setCustomer(customer)"
            >
              <v-list-item-icon>
                <v-icon v-text="'mdi-account'"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="customer.name ? customer.name : ''"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  props: ["customerModal"],
  data: () => ({
    loadCustomer: false,
    cnpj_field: "",
    requiredRules: [(v) => !!v || "Este campo é obrigatório"],
    customers: {},
  }),
  methods: {
    getCustomers() {
      if (this.$refs.searchCustomerForm.validate()) {
        this.loadCustomer = true;
        http.get("api/customers?search=" + this.cnpj_field).then((res) => {
          if (res.data.data.length >= 1) {
            this.customers = res.data.data;
          }
          this.loadCustomer = false;
        });
      }
    },
    setCustomer(customer) {
      this.$emit("set_customer", customer);
    },
  },
};
</script>